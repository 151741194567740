import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src", "onClick", "onDblclick"]

import star from "@/assets/img/star.png"
import starFill from "@/assets/img/star-fill.png"
import starNull from "@/assets/img/star-null.png"
import { toRefs, watch } from "vue"


export default {
  __name: 'starScore',
  props: {
    value: {
        type: [String,Number],
        default: 0
    }
},
  emits: ['getValue'],
  setup(__props, { emit }) {

const props = __props


const { value } = toRefs(props)
let timer = null;
let type = ref("0") // 1:半星 2:满星
let starIndex = ref(0)
let starKey = ref(false)


watch(value,(val)=>{
    if (val || val==0 || val=="0") {
        if (String(val).indexOf(".5")==-1) { //整数
            starIndex.value = Number(val);
            type.value = "2";
        } else {
            starIndex.value = Math.ceil(Number(val));
            type.value = "1";
        }
        // starKey.value = !starKey.value;
    }
},{
    deep: true,immediate: true
})

const clickStar = (index) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
        type.value = "1";
        if (value.value!=index-0.5) {
            starIndex.value = index;
            emit('getValue', index-0.5);
        }
    }, 300);
}
const dbclickStar = (index) => {
    type.value = "2";
    if (value.value!=index) {
        starIndex.value = index;
        emit('getValue', index);
    }
    clearTimeout(timer)
}
const show = (index) => {
    if (index<starIndex.value) {
        return starFill;
    } else if(index==starIndex.value) {
        if (type.value=="2") {
            return starFill;
        } else {
            return star;
        }
    } else {
        return starNull;
    }
}


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("div", { key: _unref(starKey) }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
        return _createElementVNode("img", {
          src: show(index),
          alt: "",
          key: index,
          onClick: $event => (clickStar(index)),
          onDblclick: $event => (dbclickStar(index))
        }, null, 40, _hoisted_1)
      }), 64))
    ]))
  ]))
}
}

}
<template>
    <div class='dataMaintenanceList'>
        <main>
            <div class='subToolbar'>
<!--                <div class="title">数据维护列表</div>-->
                <div class='btns'>
                    <el-button type='primary' @click="editItem(null, '新增指标')"
                    >新增指标
                    </el-button
                    >
                </div>
            </div>
            <div class='table'>
                <el-table
                    :data='tableData'
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style='width: 100%; height: 100%'
                    header-row-class-name='table-header'
                    height='calc(100% - 40px)'
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                >
                    <el-table-column type='index' width='90' label='序号' />
                    <el-table-column prop='year' label='年度' show-overflow-tooltip></el-table-column>
                    <el-table-column prop='quarter' label='季度' show-overflow-tooltip>
                        <template #default='{ row }'>
                            <div>第{{ quarterObj[row.quarter] }}季度</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop='limitTime' label='更新时间'>
                        <template #default='{ row }'>
                            <div>{{ row.changeTime }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label='操作'>

                        <template #default='{ row }'>
                            <el-button type='text' size='small' @click="editItem(row, '查看指标')">查看</el-button>
                            <el-button
                                type='text'
                                size='small'
                                @click="editItem(row, '修改指标')"
                            >编辑
                            </el-button
                            >

                            <el-button type='text' size='small'>
                                <el-popconfirm title='确定删除吗？' @confirm='handleDel(row)'>
                                    <template #reference>
                                        <span style='color: red' slot='reference'>删除</span>
                                    </template>
                                </el-popconfirm>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class='pagination'>
                    <el-pagination
                        v-model:currentPage='query.page'
                        v-model:page-size='query.size'
                        :page-sizes='[15, 30, 50, 100]'
                        background
                        layout='total, sizes, prev, pager, next, jumper'
                        :total='total'
                        @size-change='getList'
                        @current-change='getList'
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
        <dataMaintenanceDialog
            v-if='indexEditDialog'
            :propsData='propsData'
            :title='title'
            @close='indexEditClose'
        ></dataMaintenanceDialog>
    </div>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import dataMaintenanceDialog from './dataMaintenanceDialog';
import axios from 'axios';
import http from '../../utils/request';
const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
let query = ref({
    page: 1,
    size: 15,
});
let quarterObj={
    1:'一',
    2:'二',
    3:'三',
    4:'四',
}
let indexEditDialog = ref(false);
let total = ref(0);
let title = ref('');
let propsData = ref({});
let tableData = ref([]);
// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});

function indexEditClose(status) {
    indexEditDialog.value = false;
    if(status=='update'){
        getList();
    }
}

function editItem(row = null, name) {
    indexEditDialog.value = true;
    title.value = name;
    propsData.value = row;
}

// 指标删除
function handleDel(row) {
    http.get('/front/frontData/delete', {params:{ id:row.id }})
        .then((res) => {
            if (res.code == 200) {
                ElMessage({
                    message: '删除成功',
                    type: 'success',
                })
                getList();
            }else{
                ElMessage({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
        .catch((err) => {
            console.log(err);
        });

}

function getList() {
        http.get('/front/frontData/getPage',{params:query.value} )
        .then((res) => {
            if (res.code == 200) {
                tableData.value = res.data.records;
                total.value = res.data.total;
            }
        })
        .catch((err) => {
            console.log(err);
        });


}

onMounted(() => {
    getList();
});
</script>

<style scoped lang='less'>
.dataMaintenanceList {
    height: calc(100% - 32px);

    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;

        .btns {
            white-space: nowrap;
        }
    }

    main {
        margin-top: 16px;
        height: calc(100% - 85px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;

        .subToolbar {
            text-align: right;
            margin-bottom: 16px;

            .el-button {
                margin-right: 8px;
            }
        }

        .table {
            height: calc(100% - 76px);

            .table-header {
                height: calc(100% - 40px);
            }

            .pagination {
                padding-top: 10px;
                text-align: right;
            }
        }

        /deep/ .el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
}
</style>
